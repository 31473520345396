import { useMediaQuery } from 'beautiful-react-hooks';
import { useState } from 'react';
import { InlineWidget } from 'react-calendly';
import Config from '../config/Config';
import ModalStatus from '../const/modal';
import { useApp } from '../providers/App';
import { ROLES, useAuth } from '../providers/Auth';
import styles from '../styles/UtilitySection.module.css';
import Modal from './Modal';

export default function UtilitySection() {
  const [openCalendlyModal, setOpenCalendlyModal] = useState(false);
  const { hasRole } = useAuth();
  const { setGlobalModalStatus } = useApp();
  const handleBook = () =>
    hasRole(ROLES.simulator)
      ? setOpenCalendlyModal(true)
      : setGlobalModalStatus(ModalStatus.Upgrade);
  const closeCalendlyModal = () => {
    setOpenCalendlyModal(false);
  };
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div data-testid="utility-section" className="flex">
      <div className={styles.container}>
        <div className="items-start">
          <div className="pr-4 pb-6 lg:py-10">
            <h1>{hasRole(ROLES.simulator) ? 'Member' : 'SoleSavy'} FAQ</h1>
            <p>Got questions? View the questions frequently asked by other members.</p>
            <a
              className="flex items-center"
              target="_blank"
              href={hasRole(ROLES.simulator) ? Config.MEMBER_FAQ : Config.GENERAL_FAQ}
              tabIndex={0}
              rel="noreferrer"
            >
              {hasRole(ROLES.simulator) ? 'Member' : 'SoleSavy'} FAQ
              <span className="pl-1 text-red-500 font-bold text-xs">&nbsp;&rarr;</span>
            </a>
          </div>
        </div>

        <div
          className={`${
            hasRole(ROLES.simulator) ? styles.border : 'order-1 lg:items-end'
          } items-start`}
        >
          <div className="lg:px-4 py-6 lg:py-10">
            <h1>Download COLLECT</h1>
            <p>
              COLLECT is the marketplace where you can buy, sell and trade all in one place with the
              lowest fees.
            </p>
            <a
              className="flex items-center"
              role="button"
              tabIndex={0}
              onClick={handleBook}
              onKeyPress={handleBook}
            >
              {hasRole(ROLES.simulator) ? (
                <>
                  Get it now
                  <span className="pl-1 text-red-500 font-bold text-xs">&nbsp;&rarr;</span>
                </>
              ) : (
                <>
                  Upgrade
                  <embed
                    className="pl-1"
                    src="/icons/lock-red.svg"
                    width={16}
                    height={16}
                    tabIndex={-1}
                  />
                </>
              )}
            </a>
          </div>
        </div>

        <div className={`${hasRole(ROLES.simulator) ? 'lg:items-end' : styles.border} items-start`}>
          <div className="lg:pl-4 py-6 lg:py-10">
            <h1>Contact us</h1>
            <p>Need help? Get in touch with the SoleSavy support team for assistance.</p>
            <a className="flex items-center" href={Config.GET_HELP} tabIndex={0}>
              Get help<span className="pl-1 text-red-500 font-bold text-xs">&nbsp;&rarr;</span>
            </a>
          </div>
        </div>
      </div>

      <Modal openModal={openCalendlyModal} handleClose={closeCalendlyModal} overrideInternalModal>
        <InlineWidget
          styles={{
            width: '90vw',
            maxWidth: '650px',
            height: isMobile ? '75vh' : '90vh',
            borderRadius: '.75rem',
          }}
          url={Config.COLLECT_PAGE_URL}
        />
      </Modal>
    </div>
  );
}
