import Image from 'next/image';
import Link from 'next/link';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useRouter } from 'next/router';
import Release from '../../interfaces/release';
import styles from '../../styles/ReleaseItem.module.css';
import Button from '../Button';
import ReleaseGuideWithRaffle from './ReleaseGuideWithRaffle';
import ReleaseStatus from '../../const/release';
import dateInPast from '../../utils/dateInPast';
import ReleaseDate from './ReleaseDate';

const ReleaseItem = (release: Release) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const router = useRouter();
  const rel = { ...release };
  const shoePage =
    !!rel.sku && ![ReleaseStatus.TBA, ReleaseStatus.TBD, ReleaseStatus.NA].includes(rel.sku)
      ? `/release/${rel.sku}`
      : `/release/${rel.id}`;
  let releaseGuide = ReleaseStatus.TBA;
  let released = false;
  let imageURL = rel.image?.asset?.url || '/images/placeholder-small.jpg';
  imageURL += '?h=128';
  const imageAlt = rel.image?.alt ? rel.image.alt : `${rel.title} official release imagery`;

  if (rel?.releaseDate) {
    if (dateInPast(rel.releaseDate, true)) {
      releaseGuide = ReleaseStatus.Released;
      released = true;
    } else if (rel.strategy) {
      releaseGuide = ReleaseStatus.Live;
    }
  }

  const handleClick = () => router.push(shoePage.trim() === shoePage ? shoePage : `${shoePage}#`); // force to include trailing space

  return (
    <div
      className={`${styles.grid}`}
      tabIndex={-1}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="button"
      title={rel.title}
      data-href={shoePage}
    >
      <div className={`${styles.relDateGrid} md:justify-self-start xs:self-start`}>
        <ReleaseDate releaseDate={rel?.releaseDate} />
      </div>
      <div
        className={`justify-self-start ${styles.shoeImageGrid} ${
          released ? styles.grayedOut : ''
        } md:pb-0 pb-4`}
      >
        {isMobile ? (
          <Link href={shoePage} passHref>
            <Image
              src={`${imageURL}`}
              width={128}
              height={92.8}
              alt={`${imageAlt}`}
              className={`${styles.shoeImage} rounded`}
            />
          </Link>
        ) : (
          <Image
            src={`${imageURL}`}
            width={128}
            height={92.8}
            alt={`${imageAlt}`}
            className={`${styles.shoeImage} rounded`}
          />
        )}
      </div>
      <div
        className={`${styles.shoeTextGrid} ${styles.centerShoeName} ${
          released ? styles.grayedOut : ''
        }`}
      >
        <ul>
          <li>
            {isMobile ? (
              <Link href={shoePage} passHref>
                <span className={`text-lg font-labMedium ${released ? styles.grayedOut : ''}`}>
                  {rel.title}
                </span>
              </Link>
            ) : (
              <span className={`text-lg font-labMedium ${released ? styles.grayedOut : ''}`}>
                {rel.title}
              </span>
            )}
          </li>
          <li>
            <span className={`text-gray-700 font-labRegular ${released ? styles.grayedOut : ''}`}>
              {rel.retail ? `${rel.retail} ${rel.currency}` : 'TBA'}
            </span>
          </li>
        </ul>
      </div>
      <div
        className={`text-xs font-labMedium text-gray-700 ${styles.center} ${
          released ? styles.grayedOut : ''
        } ${styles.guideAndRaffleGrid}`}
      >
        <ReleaseGuideWithRaffle
          shoePage={shoePage}
          releaseGuide={releaseGuide}
          raffles={rel.raffles}
        />
      </div>
      <div className={`${styles.detailsButtonGrid} ${styles.center} justify-self-end`}>
        <Button
          secondary
          border
          btnContext="DETAILS"
          handleClick={handleClick}
          canActivate
          padding="px-3.5 py-1.5 sm:py-4 sm:px-4"
          customClasses="h-8 w-24 text-xs tracking-custom"
          href={shoePage}
        />
      </div>
    </div>
  );
};
export default ReleaseItem;
