import { useMediaQuery } from 'beautiful-react-hooks';
import Image from 'next/image';
import Config from '../../../config/Config';
import OnboardingCard from '../OnboardingCard';

const listItems = [
  {
    icon: 'drops',
    children: (
      <p>
        Set up personalized <span className="text-gray-1000">drop alerts</span>
      </p>
    ),
  },
  {
    icon: 'updates',
    children: (
      <p>
        Read upcoming <span className="text-gray-1000">release guides</span>
      </p>
    ),
  },
  {
    icon: 'launches',
    children: (
      <p>
        Manage all your sneaker <span className="text-gray-1000">raffles</span> in one place
      </p>
    ),
  },
];

const appleButton = (
  <a href={Config.SOLESAVY_APPLE_APP} className="w-32">
    <Image src="/images/apple.svg" alt="apple" width="32" height="9" layout="responsive" />
  </a>
);

const androidButton = (
  <a href={Config.SOLESAVY_ANDROID_APP} className="w-32">
    <Image src="/images/google.svg" alt="google" width="32" height="9" layout="responsive" />
  </a>
);

const staticContent = {
  title: 'Download DROPS',
  description:
    'Access your on-the-go source for all sneaker releases. Download the app and sign in with your SoleSavy credentials.',
  listItems,
  rightButtons: [appleButton, androidButton],
};
const DropsAppComponent = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const leftPanel = 'solesavy-app';
  const rightIcon = (
    <div
      className={`flex rounded-custom bg-black justify-center items-center ${
        isMobile ? 'w-12 h-12' : 'w-20 h-20'
      }`}
    >
      <Image src="/icons/qr-mobile-app.svg" width="80" height="80" />
    </div>
  );
  const content = {
    ...staticContent,
    leftPanel,
    rightIcon,
  };
  return <OnboardingCard onboardingCardContent={content} />;
};
const DropsApp = {
  component: DropsAppComponent,
  staticContent,
};
export default DropsApp;
