import { useRouter } from 'next/router';
import { NextPage } from 'next';
import { ReactElement } from 'react';
import { useCookies } from 'react-cookie';
import { getSsoUrl, useAuth } from '../providers/Auth';
import { isTesting } from '../utils';
import LoadingSpinner from '../components/LoadingSpinner';

const isBrowser = (): boolean => typeof window !== 'undefined';

const DefaultLoadingFallback = (): ReactElement => <LoadingSpinner />;

export default function withAuthRedirect<CP = {}, IP = CP>({
  WrappedComponent,
  LoadingComponent = DefaultLoadingFallback,
  expectedAuth,
  location,
}: {
  WrappedComponent: NextPage<CP, IP>;
  LoadingComponent?: NextPage;
  expectedAuth: boolean;
  location: string;
}): NextPage<CP, IP> {
  const WithAuthRedirectWrapper: NextPage<CP, IP> = (props) => {
    const [cookie] = useCookies(['pendingVerification']);
    const router = useRouter();
    const { isLoading, isAuthenticated } = useAuth();
    const [, , removeCookie] = useCookies(['access_token']);

    if (isLoading) {
      return <LoadingComponent />;
    }
    if (isBrowser() && expectedAuth !== isAuthenticated) {
      if (router.asPath !== '/' && router.asPath !== '/logout') {
        localStorage.setItem('path', router.asPath);
      }
      removeCookie('access_token');
      router.push(
        location || cookie.pendingVerification
          ? `/verify?${cookie.pendingVerification}&mode=resume`
          : getSsoUrl()
      );
      return <LoadingComponent />;
    }
    if (router.asPath === '/' && localStorage.getItem('path')) {
      router.push(localStorage.getItem('path') ?? '/');
      localStorage.removeItem('path');
      return <LoadingComponent />;
    }
    return <WrappedComponent {...props} />;
  };

  if (isTesting()) {
    return WrappedComponent;
  }

  return WithAuthRedirectWrapper;
}
