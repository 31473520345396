import Image from 'next/image';
import styles from '../styles/LoadingSpinner.module.css';
import DashboardLayout from './DashboardLayout';

const LoadingSpinner = ({ pageName }: { pageName?: string }) => (
  <DashboardLayout pageName={pageName || 'Loading...'} loading>
    <div
      data-testid="loading-spinner"
      className="flex justify-center items-center relative h-full -top-8"
    >
      <div
        className={` ${styles.loader} loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-48 w-48`}
      />
      <span className="absolute">
        <Image src="/ss_logo.svg" alt="SoleSavy Logo" width={112} height={40} />
      </span>
    </div>
  </DashboardLayout>
);

LoadingSpinner.defaultProps = {
  pageName: 'Loading...',
};

export default LoadingSpinner;
