import { useRouter } from 'next/router';
import { MouseEvent, KeyboardEvent } from 'react';
import styles from '../../styles/ReleaseItem.module.css';
import ReleaseStatus from '../../const/release';
import { Raffle } from '../../interfaces/release';
import dateInPast from '../../utils/dateInPast';

export interface ReleaseGuideWithRaffleProps {
  releaseGuide: string;
  raffles: Raffle[] | undefined;
  centerItems?: boolean;
  shoePage?: string;
}

const ReleaseGuideWithRaffle = ({
  releaseGuide,
  raffles,
  centerItems,
  shoePage,
}: ReleaseGuideWithRaffleProps) => {
  const raffleCount = (raffles || []).filter(
    (raffle) => !dateInPast(raffle.endDate) && raffle.link !== undefined
  ).length;
  const router = useRouter();

  const handleClick = (
    evt: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent> | KeyboardEvent<HTMLAnchorElement>,
    url: string
  ) => {
    evt.stopPropagation();
    router.push(`${shoePage}#${url}`);
  };

  const released = !(shoePage && releaseGuide === ReleaseStatus.Live);

  return (
    <div className="flex text-xs font-labMedium text-gray-700 w-full justify-evenly gap-5">
      <ul className={centerItems ? 'flex flex-col items-center' : ''}>
        <li
          className={`pb-2 text-xxs tracking-custom2 ${
            released && releaseGuide !== ReleaseStatus.Live ? styles.grayedOut : ''
          }`}
        >
          RELEASE GUIDE
        </li>
        <li>
          <span
            className={`${
              releaseGuide === ReleaseStatus.Live
                ? styles.releaseGuideGreen
                : styles.releaseGuideGrey
            }`}
          >
            {shoePage && releaseGuide === ReleaseStatus.Live ? (
              <a
                tabIndex={0}
                onClick={(evt) => handleClick(evt, `tab-guide`)}
                onKeyPress={(evt) => handleClick(evt, `tab-guide`)}
                role="button"
              >
                {releaseGuide}
              </a>
            ) : (
              releaseGuide
            )}
          </span>
        </li>
      </ul>
      <ul className={centerItems ? 'flex flex-col items-center' : ''}>
        <li
          className={`pb-2 text-xxs tracking-custom2 ${raffleCount === 0 ? styles.grayedOut : ''}`}
        >
          ACTIVE RAFFLES
        </li>
        <li>
          <span className={`${raffleCount > 0 ? styles.rafflesGreen : styles.rafflesGrey}`}>
            {shoePage && raffleCount > 0 ? (
              <a
                tabIndex={0}
                onClick={(evt) => handleClick(evt, `tab-raffles`)}
                onKeyPress={(evt) => handleClick(evt, `tab-raffles`)}
                role="button"
              >
                {raffleCount.toString()}
              </a>
            ) : (
              raffleCount
            )}
          </span>
        </li>
      </ul>
    </div>
  );
};

ReleaseGuideWithRaffle.defaultProps = {
  centerItems: false,
  shoePage: '',
};

export default ReleaseGuideWithRaffle;
