import { formatDate } from '../../utils/releases';

export interface ReleaseDateProps {
  releaseDate: string | undefined;
}

const ReleaseDate = ({ releaseDate }: ReleaseDateProps) => {
  const releaseDateText = formatDate(releaseDate).split(' ');

  return (
    <div>
      <ul className="flex flex-col items-center">
        <li className="leading-4">
          <span className="font-tommyBold md:text-sm text-xs">
            {releaseDateText[0].toLocaleUpperCase()}
          </span>
        </li>
        <li className="leading-5">
          <span className="font-tommyBold leading-5 md:text-2xl text-lg">{releaseDateText[1]}</span>
        </li>
      </ul>
    </div>
  );
};

export default ReleaseDate;
