import Link from 'next/link';

import Release from '../../interfaces/release';
import ReleaseItem from '../Releases/ReleaseItem';
import Config from '../../config/Config';
import styles from '../../styles/ReleaseItem.module.css';

interface ReleaseUpcomingSectionProps {
  releases: Release[] | [];
  other?: boolean;
}

const emptyUpcomingReleases = () => (
  <div className="p-14 flex justify-center items-center border-dashed border-gray-400 border-2 rounded text-gray-800">
    There&apos;s no upcoming release, check back later!
  </div>
);

const ReleaseUpcomingSection = ({ releases, other }: ReleaseUpcomingSectionProps) => (
  <div
    data-testid="upcoming-section"
    className={`${styles.upcoming} mb-12 ${other ? 'mt-24' : ''}`}
  >
    <div className="flex justify-between mb-5">
      <h1 className="font-labBold text-sm leading-sectionTitles text-gray-600 font-bold">
        {other ? 'OTHER UPCOMING RELEASES' : 'UPCOMING RELEASES'}
      </h1>
      <Link href={Config.RELEASES_URL} passHref>
        <a className="font-medium text-gray-1000 text-base">
          All releases<span className="pl-1 text-red-500 font-bold text-xs">&nbsp;&rarr;</span>
        </a>
      </Link>
    </div>
    <div className="filter drop-shadow-1">
      {releases.length !== 0
        ? releases.map((release) => <ReleaseItem {...release} key={release.id} />)
        : emptyUpcomingReleases()}
    </div>
  </div>
);

ReleaseUpcomingSection.defaultProps = {
  other: false,
};

export default ReleaseUpcomingSection;
