import moment from 'moment-timezone';

const dateFormat = 'YYYYMMDD';
const monthFormat = 'YYYYMM';

export const timezone = 'America/New_York';

export const formatDate = (date: string | Date | undefined, withYear = false) =>
  moment(date)
    .tz(timezone) // convert to EST in any device
    .format(withYear ? 'MMM D YYYY' : 'MMM D');

export const formatDateTime = (date: string) => {
  const formattedDate = moment(date)
    .tz(timezone)
    .add(date.includes('-0500') && moment(date).isDST() ? -1 : 0, 'hour')
    .format('MMM D, YYYY - h:mmA');
  const timezoneText = moment(date).isDST() ? 'EDT' : 'EST';
  return `${formattedDate} ${timezoneText}`;
};

const getCurrentStartDate = (isMonth = false) => {
  const dateStart = moment().add(1, 'day');

  dateStart.startOf(isMonth ? 'month' : 'isoWeek');
  if (!isMonth) {
    dateStart.subtract(1, 'day');
  }
  return dateStart.format(isMonth ? monthFormat : dateFormat);
};

export const getCurrentWeek = () => moment().utcOffset(0).startOf('week').format(dateFormat);

export const getDateRange = (date: string) => {
  const mode = date?.length === 6 ? 'month' : 'isoWeek';
  const dateStart = moment(date).add(1, 'day'); // avoids edge dates, will reset to start/end of week/month anyway
  const dateEnd = moment(date).add(1, 'day');

  dateStart.startOf(mode);
  dateEnd.endOf(mode);
  if (mode === 'isoWeek') {
    // Sunday - Saturday instead of Monday - Sunday
    dateStart.subtract(1, 'day');
    dateEnd.subtract(1, 'day');
  }

  return { dateStart: dateStart.format(dateFormat), dateEnd: dateEnd.format(dateFormat) };
};

export const getWeekParam = (weekStartDate: string) => moment(weekStartDate).format(dateFormat);

export const getMonthParam = (weekStartDate: string) => moment(weekStartDate).format(monthFormat);

export const getShiftedDate = (date: string, isMonth = false, shift = 1) =>
  moment(date)
    .add(shift, isMonth ? 'month' : 'week')
    .format(isMonth ? monthFormat : dateFormat);

export default getCurrentStartDate;
